<template>
  <b-card>
    <SidebarTrackDetail :elementDetail="elementDetail" @refreshTable="initialize"></SidebarTrackDetail>
    <div class="d-flex">
      <b-form-input
        id="filter-input"
        v-model="filter"
        type="search"
        class="col-md-4 col-xl-2"
        placeholder="Cerca..."
      ></b-form-input>
      <b-select :options="[
            {value: 'all', text: 'Tutti'},
            {value: 'processing', text: 'Lavorazione'},
            {value: 'reject', text: 'Respinta'},
            {value: 'complete', text: 'Completata'},
            {value: 'accepted', text: 'Accettato'}
        ]" style="width: 300px" class="ml-5" :placeholder="`Filtra per`"
        v-model="filterStatusValue"
        @change="filterTable"
      ></b-select>
      <b-button class="mb-3 ml-auto" variant="success"
                @click="$router.push('/track-reservation/create')"
        >+ Crea Nuova</b-button
      >
    </div>
    <b-table
      hover
      bordered
      :filter="filter"
      responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      class="contact-table"
    >
        <!-- A custom formatted column -->
      <template #cell(track_type)="data" width="100" class="text-center">
        <span v-if="data.item.track_type === 'round_trip'"> Andata e Ritorno</span>
        <span v-else> Solo Andata</span>
      </template>
      <template #cell(patient_or_clinic)="data" width="60" class="text-center">
          <span v-if="data.item.user_patient_id && data.item.caregiver_vector">{{data.item.caregiver_vector.name}}</span>
          <span v-else-if="data.item.user_caregiver_id && data.item.caregiver">
            {{data.item.caregiver.authinfo.name}} {{data.item.caregiver.authinfo.surname}}
        </span>
          <span v-else-if="data.item.user_patient_id && data.item.patient">
            {{data.item.patient.authinfo.name}} {{data.item.patient.authinfo.surname}}
        </span>
          <span v-if="data.item.user_clinic_id">(Clinica) {{data.item.clinic.name}}</span>
        <span v-if="data.item.user_clinic_id">(Clinica) {{data.item.clinic.name}}</span>
      </template>
      <template #cell(types_of_service)="data" width="60" class="text-left">
        <span>{{data.item.track.description}}</span>
      </template>
      <template #cell(status)="data" width="60" class="text-left">
        <span><b-button :variant="getButtonVariant(data.item.status)" size="sm" disabled="true">{{ getLabelStatus(data.item.status) }}</b-button></span>
      </template>
      <template #cell(CambiaStato)="data" width="60" class="text-left">
        <span>
          <b-button
              v-if="isVectorUser === data.item.user_vector_id && data.item.status === 'processing'"
              @click="completeTrack(data.item.payment_intent, data.item.id)"
              :variant="getButtonVariant('complete')"
              >Completa
          </b-button>
          <b-button
              v-if="isVectorUser === data.item.user_vector_id && data.item.status === 'accepted'"
              @click="processingTrack(data.item.id)"
              :variant="getButtonVariant('processing')"
              >Lavorazione
          </b-button>
        </span>
      </template>
      <template #cell(Azioni)="data" width="100" class="text-center">
<!--           @click="$router.push('/track-reservation/edit/'+data.item.id)"-->
        <a class="mx-1 cursor-pointer"
           @click="setElementDetail(data.item)"
           v-b-toggle.track_detail_ref
        ><feather type="edit-3" class="feather-sm"></feather
        ></a>
<!--        <a-->
<!--            class="mx-1 cursor-pointer text-danger"-->
<!--            @click="deleteItem(data.item)"-->
<!--        ><feather type="trash-2" class="text-danger feather-sm"></feather-->
<!--        ></a>-->
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
      ref="edit-modal"
      id="editModal"
      hide-header
      @ok="save"
      @cancel="close"
      ok-title="Salva"
      cancel-title="Chiudi"
    >
      <h4 class="mb-4">{{ formTitle }}</h4>
      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Name</h6>
          <b-form-input
            v-model="editedItem.authinfo.name"
            placeholder="Inserisci Nome"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Email</h6>
          <b-form-input
            v-model="editedItem.authinfo.email"
            placeholder="Inserisci email"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Password</h6>
          <b-form-input
              v-model="editedItem.authinfo.password"
              placeholder="Inserisci password"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import SidebarTrackDetail from "@/views/track_reservation/components/SidebarTrackDetail";

export default {
  name: "TrackReservationList",
  components: {
    SidebarTrackDetail
  },
  data: () => ({
    isVectorUser: false,
    sortBy: "id",
    fields: [
      { key: "id", sortable: true },
      { key: "patient_or_clinic", label: "Paziente/Clinica" },
      { key: "track_type", label: "Tipologia" },
      { key: "status", label: "Stato" },
      { key: "departure_date", label: "Data di partenza" },
      { key: "types_of_service", label: "Servizi" },
      "CambiaStato",
      "Azioni"
    ],
    elementDetail: null,
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      authinfo: {
        name: '',
        email: '',
      },
    },
    defaultItem: {
      id: "",
      authinfo: {
        name: '',
        email: '',
      },
    },
    filterStatusValue: 'all'
  }),
  created() {
    this.initialize();
    const that = this
    setTimeout(function () {
      if (that.user.user_rule === 'vector') {
        that.isVectorUser = that.user.user.id
      } else {
        that.isVectorUser = false
      }
    }, 400)
  },
  methods: {
    getButtonVariant(status) {
      let variant = 'primary'

      if (status === 'new') {
        variant = 'info'
      } else if (status === 'processing') {
        variant = 'warning'
      } else if (status === 'hold' || status === 'holded') {
        variant = 'danger'
      } else if (status === 'reject') {
        variant = 'danger'
      } else if (status === 'complete') {
        variant = 'success'
      } else if (status === 'accepted') {
        variant = 'primary'
      }

      return variant
    },
    getLabelStatus(status) {
      let label = status

      if (status === 'new') {
        label = 'Nuova'
      } else if (status === 'processing') {
        label = 'Lavorazione'
      } else if (status === 'hold' || status === 'holded') {
        label = 'Bloccato'
      } else if (status === 'reject') {
        label = 'Respinta'
      } else if (status === 'complete') {
        label = 'Completata'
      } else if (status === 'accepted') {
        label = 'Accettato'
      }

      return label
    },
    async completeTrack(payment_intent, track_id) {
      const res = await this.axios.post(`stripe/defrost/payment`, {
        'payment_intent': payment_intent,
        'track_id': track_id,
      })

      if (res.data.status === 'error') {
        this.$swal.fire({
          title: 'Completa!',
          text: res.data.message,
          icon: 'error',
          confirmButtonColor: '#1f2335',
        })
      } else {
        this.$swal.fire({
          title: 'Completa!',
          text: 'La corsa è stata completata e il pagamento è stato rilasciato',
          icon: 'success',
          confirmButtonColor: '#1f2335',
        })
        await this.initialize()
      }
    },
    async processingTrack(trackId) {
      const res = await this.axios.post(
          `track-reservation/${trackId}/vector/${this.isVectorUser}/processing`
      );
      this.$swal.fire({
        title: 'Corsa In Lavorazione!',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      await this.initialize()
    },
    async filterTable() {
      if (this.filterStatusValue === 'all') {
        await this.initialize()
      } else {
        await this.$store.dispatch("setDataTable", [])
        const res = await this.axios.get(`track-reservations?filterField=status&filterValue=${this.filterStatusValue}`)
        await this.$store.dispatch("setDataTable", res.data)
      }
    },
    async initialize() {
      await this.$store.dispatch("setDataTable", [])
      const res = await this.axios.get('track-reservations?filterField=status&filterOperator=notIn&filterValue=new,draft')
      await this.$store.dispatch("setDataTable", res.data)
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.$refs["edit-modal"].show();
    },

    newItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    setElementDetail(item) {
      this.elementDetail = item
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await this.$swal.fire({
        title: 'Elimina Prenotazione!',
        text: 'Sei sicuro di voler eliminare?',
        icon: 'warning',
        confirmButtonColor: '#1f2335',
        confirmButtonText: 'Si, Elimina!',
        showCancelButton: true,
        cancelButtonText: 'No!'
      }).then(async result => {
        if (result.value) {
          const res = await this.axios.delete('track-reservation/'+item.id)

          if (res.data.status === 'success') {
            this.items.splice(index, 1);
          }

          this.$swal.fire({
            title: 'Eliminazione Prenotazione!',
            text: res.data.message,
            icon: res.data.status,
            confirmButtonColor: '#1f2335',
          })
        }
      });

      await this.initialize()
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async save() {
      const res = await this.axios.post('track-reservation', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio Utente',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.close();
      await this.initialize();
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo Utente Admin" : "Modifica Utente Admin";
    },
    rows() {
      return this.items.length;
    },
    items () {
      return this.$store.state.dataTable
    },
    user(){
      return this.$store.state.authentication;
    }
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>