<template>
  <b-sidebar v-if="elementDetail" id="track_detail_ref" :title="`#${elementDetail.id} - Dettaglio Corsa`" right shadow>
    <b-row class="px-3 py-2 text-center">
      <b-col cols="12" md="6">
        <b-badge class="mr-1" variant="info">
          {{ elementDetail.track_type === 'round_trip' ? 'Andata e Ritorno' : 'Solo Andata'  }}
        </b-badge>
        <b-badge class="mr-1" :variant="getButtonVariant(elementDetail.status)">{{ getLabelStatus(elementDetail.status) }}</b-badge>
        <h5>{{ elementDetail.vector.authinfo.name }}</h5>
      </b-col>
      <b-col cols="12" md="6" class="text-left">
        <p>{{ elementDetail.track.description }}</p>
      </b-col>
      <template v-if="elementDetail.caregiver">
        <b-col cols="12">
          <hr data-content="CAREGIVER" class="hr-text">
          <b-row>
            <b-col cols="12" md="6">
              <p><feather class="feather-sm" type="user"></feather> {{ elementDetail.caregiver.authinfo.name +' '+elementDetail.caregiver.authinfo.surname }}</p>
              <p><feather class="feather-sm" type="mail"></feather> {{ elementDetail.caregiver.authinfo.email }}</p>
            </b-col>
            <b-col cols="12" md="6">
              <p><feather class="feather-sm" type="calendar"></feather> {{ elementDetail.caregiver.dob }}</p>
              <p><feather class="feather-sm" type="phone"></feather> {{ elementDetail.caregiver.phone_number }}</p>
            </b-col>
          </b-row>
        </b-col>
      </template>
      <b-col cols="12">
        <hr data-content="PAZIENTE" class="hr-text">
        <b-row>
          <b-col cols="12" md="6">
            <p><feather class="feather-sm" type="user"></feather> {{ elementDetail.patient.authinfo.name +' '+elementDetail.patient.authinfo.surname }}</p>
            <p><feather class="feather-sm" type="mail"></feather> {{ elementDetail.patient.authinfo.email }}</p>
          </b-col>
          <b-col cols="12" md="6">
            <p><feather class="feather-sm" type="calendar"></feather> {{ elementDetail.patient.dob }}</p>
            <p><feather class="feather-sm" type="phone"></feather> {{ elementDetail.patient.phone_number }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <hr data-content="INDICAZIONI INDIRIZZO" class="hr-text">
      </b-col>
      <b-col cols="12" md="6">
        <hr data-content="ANDATA" class="hr-text">
        <p><feather class="feather-sm" type="calendar"></feather> {{ elementDetail.departure_date }}</p>
        <p><feather class="feather-sm" type="map-pin"></feather>
          {{ elementDetail.departure_street_to }}
<!--          <span v-if="elementDetail.payments_info.tripData.departure.healty_place||false">-->
<!--            - {{ elementDetail.payments_info.tripData.departure.healty_place }}-->
<!--          </span>-->
        </p>
        <p><feather class="feather-sm" type="flag"></feather>
          {{ elementDetail.departure_street_arrive }}
<!--          <span v-if="elementDetail.payments_info.tripData.departure.healty_place_arrive||false">-->
<!--            - {{ elementDetail.payments_info.tripData.departure.healty_place_arrive }}-->
<!--          </span>-->
        </p>
      </b-col>
      <template v-if="elementDetail.track_type === 'round_trip'">
        <b-col cols="12" md="6">
          <hr data-content="RITORNO" class="hr-text">
          <p><feather class="feather-sm" type="calendar"></feather> {{ elementDetail.return_date }}</p>
          <p><feather class="feather-sm" type="map-pin"></feather>
            {{ elementDetail.departure_street_arrive }}
<!--            <span v-if="elementDetail.payments_info.tripData.departure.healty_place_arrive||false">-->
<!--              - {{ elementDetail.payments_info.tripData.departure.healty_place_arrive }}-->
<!--            </span>-->
          </p>
          <p><feather class="feather-sm" type="flag"></feather>
            {{ elementDetail.return_street }}
<!--            <span v-if="elementDetail.payments_info.tripData.returns.healty_place||false">-->
<!--              - {{ elementDetail.payments_info.tripData.returns.healty_place }}-->
<!--            </span>-->
          </p>
        </b-col>
      </template>
      <b-col cols="12">
        <hr data-content="Note" class="hr-text">
        <p class="text-center">{{ elementDetail.notes||'Non ci sono note' }}</p>
      </b-col>
      <b-col cols="12">
        <hr data-content="Info Pagamenti" class="hr-text">
      </b-col>
      <b-col cols="12" md="4">
        <b-row>
          <b-col cols="8" class="text-left font-18">
            <span class="text-primary">Distanza</span>
          </b-col>
          <b-col cols="4" class="text-right">
            {{ paymentsInfo.km }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8" class="text-left font-18">
            <span class="text-primary">Costo Servizio</span>
          </b-col>
          <b-col cols="4" class="text-right">
            {{ paymentsInfo.vector_cost_service }} €
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8" class="font-18 text-left">
            <span v-if="elementDetail.payment_intent && elementDetail.payment_status === 'hold'" class="text-warning">Congelato</span>
            <span v-else-if="elementDetail.payment_intent && elementDetail.payment_status === 'paid'" class="text-success">Pagato</span>
            <span class="text-danger" v-else>Attesa Autoriz.</span>
          </b-col>
          <b-col cols="4" class="text-right">
            {{ paymentsInfo.total_cost_with_tax }} €
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="2"></b-col>
      <b-col cols="12" md="6">
        <p class="text-center">
          <b-input-group prepend="Extra" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-input
              type="number"
              step="0.01"
              min="0"
              v-model="paymentsInfo.total_cost_extra"
              placeholder="Aggiungi extra"
              size="md"
            ></b-form-input>
            <b-button @click="addExtraCost">Aggiungi</b-button>
          </b-input-group>
        </p>
      </b-col>
    </b-row>
    <div class="px-3 py-2 text-center">
      <hr data-content="CAMBIO STATO" class="hr-text">
      <div>
        <template v-if="elementDetail.status === 'new'">
          <b-button v-if="elementDetail.payment_status === 'pending'" @click="createAndSendLinkPayment(elementDetail.id)" :variant="`info`" class="mr-2">Link Pagamento</b-button>
          <b-button v-else @click="acceptTrack(elementDetail.id)" :variant="getButtonVariant('accept')" class="mr-2">Accetta</b-button>

          <b-button @click="rejectTrack(elementDetail.id)" :variant="getButtonVariant('reject')">Rifiuta</b-button>
        </template>
        <template v-else>
          <b-button v-if="elementDetail.status === 'processing'" @click="completeTrack(elementDetail.payment_intent)" :variant="getButtonVariant('complete')" class="mr-2">Completa</b-button>
          <b-button v-if="elementDetail.status === 'accepted'" @click="processingTrack(elementDetail.id)" variant="success">Lavorazione</b-button>
        </template>
      </div>
    </div>
    <div class="px-3 py-2 text-center">
      <div>
        <template v-if="elementDetail.status === 'new'">
          <b-button class="mr-2" @click="$router.push('/track-reservation/edit/'+elementDetail.id)" :variant="getButtonVariant('accept')">Modifica Corsa</b-button>
        </template>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "SidebarTrackDetail",
  props: {
    elementDetail: {
      type: Object, required: false
    }
  },
  data() {
    return {
      isVectorUser: false,
    }
  },
  methods: {
    getButtonVariant(status) {
      let variant = 'primary'

      if (status === 'new') {
        variant = 'info'
      } else if (status === 'processing') {
        variant = 'warning'
      } else if (status === 'hold' || status === 'holded') {
        variant = 'danger'
      } else if (status === 'reject') {
        variant = 'danger'
      } else if (status === 'complete') {
        variant = 'success'
      } else if (status === 'accepted') {
        variant = 'primary'
      }

      return variant
    },
    getLabelStatus(status) {
      let label = status

      if (status === 'new') {
        label = 'Nuova'
      } else if (status === 'processing') {
        label = 'In Lavorazione'
      } else if (status === 'hold' || status === 'holded') {
        label = 'Bloccato'
      } else if (status === 'reject') {
        label = 'Respinta'
      } else if (status === 'complete') {
        label = 'Completata'
      } else if (status === 'accepted') {
        label = 'Accettato'
      }

      return label
    },
    async completeTrack(payment_intent) {
      const res = await this.axios.post(`stripe/defrost/payment`, {
        'payment_intent': payment_intent,
        'track_id': this.elementDetail.id,
      })
      this.$swal.fire({
        title: 'Completa Corsa!',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.$emit('refreshTable')
    },
    async processingTrack(trackId) {
      const res = await this.axios.post(
          `track-reservation/${trackId}/vector/${this.isVectorUser}/processing`
      );
      this.$swal.fire({
        title: 'Corsa In Lavorazione!',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.$emit('refreshTable')
    },
    async createAndSendLinkPayment(trackId) {
      this.$swal.fire({
        title: 'Creazione link pagamento!',
        text: 'Se accetti, verrà creato un nuovo pagamento e inviato via mail al caregiver/paziente',
        icon: 'info',
        confirmButtonColor: '#008100',
        confirmButtonText: 'Si, Accetta!',
        cancelButtonText: 'No, Chiudi',
        cancelButtonColor: '#0B1D37',
        showCancelButton: true,
        allowOutsideClick: false,
        reverseButtons: true
      }).then(async (res) => {
        if (!res.isDismissed) {
          const resAxios = await this.axios.post(
              `/stripe/checkout/create-session/track/${trackId}`
          );
          this.$swal.fire({
            title: 'Creazione link pagamento!',
            text: resAxios.data.message,
            icon: resAxios.data.status,
            confirmButtonColor: '#1f2335',
          })
          this.$emit('refreshTable')
        }
      })
    },
    async acceptTrack(trackId) {
      this.$swal.fire({
        title: 'Accettazione Corsa!',
        text: 'Sei sicuro di voler accettare questa corsa?',
        icon: 'info',
        confirmButtonColor: '#008100',
        confirmButtonText: 'Si, Accetta!',
        cancelButtonText: 'No, Chiudi',
        cancelButtonColor: '#0B1D37',
        showCancelButton: true,
        allowOutsideClick: false,
        reverseButtons: true
      }).then(async (res) => {
          if (!res.isDismissed) {
            const resAxios = await this.axios.post(
                `track-reservation/${trackId}/vector/${this.isVectorUser}/accept`
            );
            this.$swal.fire({
              title: 'Accettazione Corsa!',
              text: resAxios.data.message,
              icon: resAxios.data.status,
              confirmButtonColor: '#1f2335',
            })
            this.$emit('refreshTable')
          }
      })
    },
    async addExtraCost() {
      const res = await this.axios.post(
          `track-reservation/${this.elementDetail.id}/add-extra-cost/${this.paymentsInfo.total_cost_extra}`
      );
      this.$swal.fire({
        title: 'Aggiunta Extra costo!',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
    },
    async rejectTrack(trackId) {
      this.$swal.fire({
        title: 'Rifiuta Corsa!',
        text: 'Sei sicuro di voler rifiutare questa corsa?',
        icon: 'info',
        confirmButtonColor: '#008100',
        confirmButtonText: 'Si, Rifiuta!',
        cancelButtonText: 'No, Chiudi',
        cancelButtonColor: '#0B1D37',
        showCancelButton: true,
        allowOutsideClick: false,
        reverseButtons: true
      }).then(async (res) => {
        if (!res.isDismissed) {
          const resAxios = await this.axios.post(
              `track-reservation/${trackId}/vector/${this.isVectorUser}/reject`
          );
          this.$swal.fire({
            title: 'Corsa Rifiutata!',
            text: resAxios.data.message,
            icon: resAxios.data.status,
            confirmButtonColor: '#1f2335',
          })
          this.$emit('refreshTable')
        }
      })
    },
  },
  computed: {
    user(){
      return this.$store.state.authentication;
    },
    paymentsInfo() {
      return this.elementDetail.payments_info
    }
  },
  mounted() {
    const that = this
    setTimeout(function () {
      if (that.user.user_rule === 'vector') {
        that.isVectorUser = that.user.user.id
      } else {
        that.isVectorUser = false
      }
    }, 400)
  },
}
</script>

<style>
#track_detail_ref {
  width: 800px !important;
}
</style>